import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import CategorySubCategoryIcon from 'Components/common/categoriesAndSubCategories/categorySubCategoryIcon';
import Conditional from 'Components/common/conditional';
import LSpan from 'Components/common/localizedTags/localizedSpan';
import DynamicWidthContentCarousel from 'Components/desktop/dynamicWidthContentCarousel';
import type {
	SectionTabInfo,
	SectionTabsCarouselProps,
} from 'Components/desktop/sectionsTabCarousel/interface';
import {
	SectionsCardCarousel,
	SectionsListItem,
	SectionsTabCarouselContainer,
} from 'Components/desktop/sectionsTabCarousel/styles';

import { getSectionContainerId } from 'Utils/cityUtils';
import { getCategoryIconUrl } from 'Utils/imageUtils';

let isAutoScrolled = false;

const SectionsTabCarousel: React.FC<
	React.PropsWithChildren<SectionTabsCarouselProps>
> = ({
	sectionTabInfos,
	topOffset = 0,
	onOverrideLazyload,
	isOnCollectionsPage,
	resetAtTop,
	bgColor,
	onSectionTabClick: onSectionTabClickFromProps,
}) => {
	const sectionObserver = useRef<IntersectionObserver | null>(null);
	const scrollEventRef = useRef<NodeJS.Timeout | null>(null);
	const sectionsTabCarouselContainerRef = useRef<HTMLDivElement | null>(null);

	const [innerWrapperWidth, setInnerWrapperWidth] = useState(0);
	const [activeSectionId, setActiveSectionId] = useState<string | null>(null);
	const [isTabCarouselSticking, setIsTabCarouselSticking] =
		useState<boolean>(false);

	const handleSectionIntersection: IntersectionObserverCallback = useCallback(
		entries => {
			const firstSection = sectionTabInfos.find(({ lazyContainerId }) =>
				entries.some(
					({ isIntersecting, target }) =>
						target.id === lazyContainerId && isIntersecting,
				),
			);

			if (firstSection && !isAutoScrolled) {
				entries.forEach(({ isIntersecting, target }) => {
					if (
						activeSectionId !== target.id &&
						isIntersecting &&
						firstSection.lazyContainerId === target.id
					) {
						setActiveSectionId(target.id);
					}
				});
			}
		},
		[activeSectionId, sectionTabInfos],
	);

	const handleScroll = () => {
		if (scrollEventRef.current) {
			clearTimeout(scrollEventRef.current);
		}

		scrollEventRef.current = setTimeout(() => {
			isAutoScrolled = false;
		}, 100);

		if (
			sectionsTabCarouselContainerRef.current &&
			sectionsTabCarouselContainerRef.current.getBoundingClientRect()
				?.top <= 128
		)
			setIsTabCarouselSticking(true);
		else setIsTabCarouselSticking(false);

		if (resetAtTop && !window.scrollY) setActiveSectionId(null);
	};

	const registerScrollObserver = useCallback(() => {
		sectionObserver.current = new IntersectionObserver(
			handleSectionIntersection,
			{
				root: null,
				rootMargin: `-${topOffset}px 0px -65% 0px`,
				threshold: 0,
			},
		);

		sectionTabInfos.forEach(sectionInfo => {
			const { lazyContainerId } = sectionInfo;
			const element = document.getElementById(lazyContainerId);

			if (element) {
				sectionObserver.current?.observe(element);
			}
		});
	}, [sectionTabInfos, topOffset]);

	const onSectionTabClick = async (
		sectionTabInfo: SectionTabInfo,
		index: number,
	) => {
		const { lazyContainerId } = sectionTabInfo;
		await onOverrideLazyload?.();
		const target = document.getElementById(lazyContainerId);
		if (target) {
			const rect = target.getBoundingClientRect();
			const scrollPosition = window.scrollY + rect.top - topOffset;
			isAutoScrolled = true;
			window.scrollTo({
				top: scrollPosition,
				behavior: 'smooth',
			});
			setActiveSectionId(target.id);
		}

		onSectionTabClickFromProps?.({ sectionTabInfo, index });
	};

	useEffect(() => {
		registerScrollObserver();

		return () => {
			sectionObserver.current?.disconnect();
		};
	}, [sectionTabInfos, registerScrollObserver]);

	useEffect(() => {
		setInnerWrapperWidth(
			(
				document.querySelector(
					'.sub-category-carousel-wrapper',
				) as HTMLDivElement
			)?.offsetWidth ?? 0,
		);

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const isCardsArrowsRequired = innerWrapperWidth > 1200;

	const sectionTabCards = sectionTabInfos.map((sectionTab, index) => {
		const { title, type, entityId, isCategory, lazyContainerId } =
			sectionTab;
		const id = getSectionContainerId({ type, title, suffix: '_tabs' });
		const isSelected = activeSectionId === lazyContainerId;
		const subCategoryCardClass = classNames({
			selected: isSelected,
		});

		return (
			<SectionsListItem key={id}>
				<SectionsCardCarousel
					className={subCategoryCardClass}
					key={id}
					id={id}
					onClick={() => onSectionTabClick(sectionTab, index)}
					categoryName={title}
				>
					<Conditional if={!isOnCollectionsPage}>
						<CategorySubCategoryIcon
							isActive={isSelected}
							name={title}
							className={'sub-category-icon'}
							svgUrl={getCategoryIconUrl({
								entityId,
								isCategory,
							})}
						/>
					</Conditional>
					<LSpan className={'sub-category-name'}>{title}</LSpan>
				</SectionsCardCarousel>
			</SectionsListItem>
		);
	});

	const settings = {
		className: 'cities-card-list',
		innerWrapperClassName: 'sub-category-carousel-wrapper',
		isSubCategoryCarousel: true,
		isCardsArrowsRequired: isCardsArrowsRequired,
	};

	const containerClass = classNames({
		'horizontal-scroll-wrapper': true,
		'no-arrow-button-city-search': !isCardsArrowsRequired,
		'sections-tab-container': true,
		'show-shadow': isTabCarouselSticking,
	});

	return (
		<SectionsTabCarouselContainer
			ref={sectionsTabCarouselContainerRef}
			isOnCollectionsPage={isOnCollectionsPage}
			className={containerClass}
			$bgColor={bgColor}
		>
			<DynamicWidthContentCarousel {...settings}>
				{sectionTabCards}
			</DynamicWidthContentCarousel>
		</SectionsTabCarouselContainer>
	);
};

export default SectionsTabCarousel;
