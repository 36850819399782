import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import { EntityIconActiveStyles } from 'Components/common/categoriesAndSubCategories/style';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

export const SectionsTabCarouselContainer = styled.div<{
	isOnCollectionsPage?: boolean;
	$bgColor?: string;
}>`
	width: 100%;
	margin: 2rem auto 1.5rem;
	padding: 1rem 0;
	position: sticky;
	top: 8rem;
	background: ${({ $bgColor }) => $bgColor ?? colors.WHITE};
	z-index: 6;
	${({ isOnCollectionsPage }) =>
		isOnCollectionsPage &&
		`
		width: 100vw;
		margin-left: calc((100vw - 75rem) / -2);
	`}

	&.show-shadow {
		box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px 0px,
			rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
		background: ${colors.WHITE};
	}

	.cities-card-list {
		width: 75rem;
		margin: 0 auto;
	}

	.prev-button-wrapper {
		left: 0;
		top: 1.375rem;
		height: 2.5rem;
		width: 2rem;
		background: ${colors.WHITE};
	}

	.next-button-wrapper {
		right: 0;
		top: 1.375rem;
		height: 2.5rem;
		width: 2rem;
		background: ${colors.WHITE};
	}

	.arrow-button {
		width: 2rem;
		height: 2rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: -1rem;

		svg {
			width: 0.75rem;
			height: 0.75rem;
		}
	}

	.arrow-button-list {
		width: 100%;
		transform: none;
	}
`;

export const SectionsListItem = styled.li`
	margin-right: 0.75rem;

	&:last-child {
		margin-right: 0;
	}
`;

export const SectionsCardCarousel = styled.button<{
	categoryName?: string;
}>`
	display: flex;
	border: 1px solid ${colors.GREY['E2']};
	justify-content: center;
	align-items: center;
	padding: 0.6875rem 0.9375rem;
	border-radius: 0.25rem;
	background: ${colors.WHITE};

	.sub-category-name {
		color: ${colors.GREY_DS.G2};
		white-space: nowrap;
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_LARGE)};
	}

	&:hover,
	&.selected {
		background: ${colors.PURPS_LEVEL[10]};
		border: 1px solid ${colors.PURPS_LEVEL[10]};

		.sub-category-name {
			color: ${colors.PURPS};
		}

		.sub-category-icon-wrapper {
			background-color: ${colors.PURPS};
		}

		.sub-category-icon {
			${EntityIconActiveStyles}
		}
	}
`;
