import React, { ReactNode, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import ArrowButtonList from 'Components/common/arrowButtonList';

import { trackEvent } from 'Utils/analytics';

import { ANALYTICS_EVENTS } from 'Constants/analytics';

const BlurEffectStyle = css`
	position: absolute;
	top: 0;
	bottom: 0;
	height: 100%;
	width: 6.25rem;
	content: '';
	background: linear-gradient(
		270deg,
		#ffffff 25%,
		rgba(255, 255, 255, 0) 100%
	);
	z-index: 1;
	display: none;
`;

export const CarouselContainer = styled.div<{
	hasPrevArrow: boolean;
	hasNextArrow: boolean;
}>`
	position: relative;

	&:before {
		${BlurEffectStyle};
		left: 0;
		background: linear-gradient(
			90deg,
			#ffffff 25%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	&:after {
		${BlurEffectStyle};
		right: 0;
		background: linear-gradient(
			270deg,
			#ffffff 25%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	${({ hasPrevArrow }) =>
		hasPrevArrow &&
		`
		&:before {
			display: block;
		}
	`}

	${({ hasNextArrow }) =>
		hasNextArrow &&
		`
		&:after {
			display: block;
		}
	`}
`;

const CarouselInnerWrapper = styled.ul`
	display: inline-flex;
	position: relative;
	width: 100%;
	transition: left 280ms ease-in-out;
`;

interface Props {
	className?: string;
	innerWrapperClassName?: string;
	children: ReactNode;
	isSubCategoryCarousel?: boolean;
	scrollAllInView?: boolean;
	trackMovement?: boolean;
	innerWrapperProps?: object;
}

const DynamicWidthContentCarousel = ({
	className,
	innerWrapperClassName,
	children,
	isSubCategoryCarousel,
	scrollAllInView,
	trackMovement,
	innerWrapperProps,
}: Props) => {
	const target = useRef<HTMLUListElement>(null);

	const [hidePrevArrow, setHidePrevArrow] = React.useState(true);
	const [hideNextArrow, setHideNextArrow] = React.useState(false);
	const [childrenWidths, setChildrenWidths] = React.useState([]);
	const [scrollBreaks, setScrollBreaks] = React.useState([]);
	const [currentBreak, setCurrentBreak] = React.useState(0);

	const getChildWidth = (child?: HTMLElement) => {
		const offsetWidth = child?.offsetWidth;
		// @ts-expect-error TS(2345): Argument of type 'HTMLElement | undefined' is not ... Remove this comment to see the full error message
		const style = getComputedStyle(child);
		const { marginLeft, marginRight } = style;

		// @ts-expect-error TS(2532): Object is possibly 'undefined'.
		return offsetWidth + parseFloat(marginLeft) + parseFloat(marginRight);
	};

	const moveToNewBreak = (direction: 'prev' | 'next' = 'next') => {
		const newBreak =
			direction === 'next'
				? Math.min(currentBreak + 1, scrollBreaks.length - 1)
				: Math.max(currentBreak - 1, 0);

		const { current: element } = target;
		// @ts-expect-error TS(2531): Object is possibly 'null'.
		element.style.left = `${-scrollBreaks[newBreak]}px`;
		setCurrentBreak(newBreak);
	};

	const onScrollChange = () => {
		const { current: element } = target,
			currentLeft = element?.style?.left,
			scrollWidth = element?.scrollWidth,
			clientWidth = element?.clientWidth;

		if (currentLeft === '0px') setHidePrevArrow(true);
		else setHidePrevArrow(false);

		if (
			scrollWidth === clientWidth ||
			// @ts-expect-error TS(2532): Object is possibly 'undefined'.
			scrollWidth - clientWidth < Math.abs(parseFloat(currentLeft))
		)
			setHideNextArrow(true);
		else setHideNextArrow(false);
	};

	const onPrev = () => {
		const { current: element } = target;
		if (scrollAllInView) {
			moveToNewBreak('prev');
		} else {
			const elementLeft = element?.style?.left;
			const currentLeft = elementLeft ? parseFloat(elementLeft) : 0;
			let newLeft = 0;

			for (let i = 0; i < childrenWidths?.length - 1; i++) {
				const childWidth = childrenWidths[i];
				newLeft -= childWidth;
				if (Math.abs(currentLeft) <= Math.abs(newLeft)) {
					// @ts-expect-error TS(2531): Object is possibly 'null'.
					element.style.left = `${newLeft + childWidth}px`;
					break;
				}
			}
		}
		if (trackMovement) {
			trackEvent({
				eventName: ANALYTICS_EVENTS.CAROUSEL.CHEVRON_CLICKED,
				direction: 'Left',
			});
		}
	};

	const onNext = () => {
		const { current: element } = target;
		if (scrollAllInView) {
			moveToNewBreak();
		} else {
			const elementLeft = element?.style?.left;
			const currentLeft = elementLeft ? parseFloat(elementLeft) : 0;
			let newLeft = 0;
			for (let i = 0; i < childrenWidths?.length - 1; i++) {
				const childWidth = childrenWidths[i];
				newLeft -= childWidth;
				if (Math.floor(currentLeft) > Math.floor(newLeft)) {
					// @ts-expect-error TS(2531): Object is possibly 'null'.
					element.style.left = `${newLeft}px`;
					break;
				}
			}
		}
		if (trackMovement) {
			trackEvent({
				eventName: ANALYTICS_EVENTS.CAROUSEL.CHEVRON_CLICKED,
				direction: 'Right',
			});
		}
	};

	const setBreakPoints = () => {
		const { current: element } = target;
		// @ts-expect-error TS(2531): Object is possibly 'null'.
		const { width } = element.getBoundingClientRect();
		const breaks = [0];
		let currentBreak = 1,
			breakAmount = 0,
			combined = 0;
		childrenWidths.forEach(w => {
			combined += w;
		});
		for (let idx = 0; idx < childrenWidths.length; idx++) {
			const itemWidth = childrenWidths[idx];
			if (breakAmount < breaks[currentBreak - 1] + width - 100) {
				breakAmount += itemWidth;
			} else {
				breakAmount -= childrenWidths[idx - 1] + 100;
				breaks.push(breakAmount);
				breakAmount = 0;
				currentBreak++;
			}
		}
		const lastBreak = combined - width + 100;
		breaks.push(lastBreak);
		// @ts-expect-error TS(2345): Argument of type 'number[]' is not assignable to p... Remove this comment to see the full error message
		setScrollBreaks(breaks);
	};

	const setChildrenWidthsOnMutation = () => {
		if (target.current) {
			const { current: element } = target;
			const { childElementCount } = element;

			element.style.left = '0px';

			const widthsArray = Array.from(Array(childElementCount)).map(
				(_, index) =>
					getChildWidth(element.children[index] as HTMLElement),
			);
			// @ts-expect-error TS(2345): Argument of type 'number[]' is not assignable to p... Remove this comment to see the full error message
			setChildrenWidths(widthsArray);
		}
	};

	useEffect(() => {
		setBreakPoints();
	}, [childrenWidths]);

	useEffect(() => {
		setChildrenWidthsOnMutation();
	}, []);

	useEffect(() => {
		onScrollChange();
	}, []);

	useEffect(() => {
		const observer = new MutationObserver(mutationList => {
			for (const mutation of mutationList) {
				if (mutation.type === 'attributes') {
					onScrollChange();
				}
			}
		});
		if (target?.current) {
			observer.observe(target.current, { attributes: true });
		}

		return () => {
			observer.disconnect();
		};
	}, []);

	return (
		<CarouselContainer
			className={className}
			hasPrevArrow={!hidePrevArrow}
			hasNextArrow={!hideNextArrow}
		>
			<ArrowButtonList
				onPrev={onPrev}
				onNext={onNext}
				hidePrev={hidePrevArrow}
				hideNext={hideNextArrow}
				isSubCategoryCarousel={isSubCategoryCarousel}
			>
				<CarouselInnerWrapper
					ref={target}
					className={innerWrapperClassName}
					{...innerWrapperProps}
				>
					{children}
				</CarouselInnerWrapper>
			</ArrowButtonList>
		</CarouselContainer>
	);
};

export default DynamicWidthContentCarousel;
