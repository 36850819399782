import { useState } from 'react';

import Image from 'Components/common/image';

import { CategorySubCategoryIconContainer } from './style';

type Props = {
	isActive: boolean;
	isCategory?: boolean;
	svgUrl: string;
	name: string;
	className?: string;
	isStarred?: boolean;
};

const CategorySubCategoryIcon = ({
	isActive,
	isCategory = false,
	svgUrl,
	name,
	className = '',
	isStarred = false,
}: Props) => {
	const [isImageLoaded, setIsImageLoaded] = useState(false);

	const handleImageLoaded = () => setIsImageLoaded(true);

	return (
		<CategorySubCategoryIconContainer
			className={className}
			$isActive={isActive && isImageLoaded}
			$isCategory={isCategory}
			$isStarred={isStarred}
			aria-hidden='true'
		>
			<Image
				src={svgUrl}
				alt={name}
				width={20}
				height={20}
				onLoad={handleImageLoaded}
				placeholder={'empty'} // image is small, so no need for blur
			/>
		</CategorySubCategoryIconContainer>
	);
};

export default CategorySubCategoryIcon;
