import styled, { css } from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

export const HorizontalTabSection = styled.div`
	margin-top: 2rem;
	margin-bottom: 0.75rem;

	.themeTabScroller {
		width: 100%;
		&::after {
			content: '';
			height: 0.0625rem;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: ${colors.GREY_DS.G6};
		}
	}

	.arrow-button-list {
		width: 100%;
		transform: none;
	}

	.prev-button-wrapper {
		left: 0;

		&::before {
			left: 0;
			background: linear-gradient(
				90deg,
				white 25%,
				rgba(255, 255, 255, 0) 100%
			);
		}
	}

	.next-button-wrapper {
		right: 0;

		&::before {
			right: 0;
			background: linear-gradient(
				270deg,
				white 25%,
				rgba(255, 255, 255, 0) 100%
			);
		}
	}

	.prev-button-wrapper,
	.next-button-wrapper {
		top: 0.875rem !important;

		&::before {
			position: absolute;
			bottom: 0;
			height: 2rem;
			width: 11.25rem;
			content: '';
			z-index: 0;
			pointer-events: none;
		}
	}

	@media (max-width: 768px) {
		margin-top: 1.5rem;
	}
`;

export const TabSectionMobile = styled.ul`
	width: 100%;
	position: relative;
	overflow-x: auto;
	display: flex;
	flex-direction: row;
	padding: 0 1.5rem;

	&::-webkit-scrollbar {
		display: none;
	}
`;

export const EntityIconActiveStyles = css`
	filter: invert(21%) sepia() saturate(10000%) hue-rotate(275deg)
		brightness(86%) contrast(100%) !important;
`;

export const CategorySubCategoryIconContainer = styled.div<{
	$isActive?: boolean;
	$isCategory?: boolean;
	$isStarred?: boolean;
}>`
	flex-shrink: 1;
	height: 18px;
	width: 18px;
	margin: 0 8px 0 0.125rem;

	img {
		${({ $isActive }) => $isActive && EntityIconActiveStyles}
	}

	@media (max-width: 768px) {
		height: 1rem;
		width: 1rem;
		margin: 2px 0.5rem 2px 2px;
	}
`;

export const SubCategoriesContainer = styled.div<{
	$isActive?: boolean;
	$isExperiencePage?: boolean;
}>`
	margin-top: ${({ $isExperiencePage }) =>
		$isExperiencePage ? '2' : '1.5'}rem;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 1rem 1.5rem;
	align-items: flex-start;
	justify-items: flex-start;
	grid-auto-flow: row;
	display: ${({ $isActive }) => !$isActive && 'none'};

	@media (max-width: 768px) {
		margin-top: ${({ $isExperiencePage }) =>
			$isExperiencePage ? '1.5' : '1'}rem;
		width: 100%;
		padding: 0
			${({ $isExperiencePage }) => ($isExperiencePage ? '0' : '1.5')}rem;
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem 1.25rem;
	}
`;

export const SubCategory = styled.a`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	max-width: calc((75rem - 1.5rem * 3) / 4);
	cursor: pointer;
	text-decoration: none;

	.subCategoryText {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)};
		flex: 1;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		//since the text-overflow is set to ellipsis and we want that underlined as well so using border instead of text-decoration
		border-bottom: 0.0625rem solid ${colors.GREY_DS.G2};
		margin-top: -2px;
	}

	&:hover {
		.subCategoryText {
			color: ${colors.BRAND_COLORS.PURPS};
		}

		.subCategoryIcon > img {
			${EntityIconActiveStyles}
		}
	}

	@media (max-width: 768px) {
		height: 1rem;
		max-width: calc((100vw - 1.25rem - 3rem) / 2);

		.subCategoryText {
			${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR)};
		}

		&:hover {
			.subCategoryText {
				border-bottom: 0.0625rem solid ${colors.BRAND_COLORS.PURPS};
			}
		}
	}
`;

export const Tab = styled.li<{
	$isActive: boolean;
	$isLast: boolean;
	$isFirst: boolean;
}>`
	z-index: 1;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 0.7s ease;
	position: relative;
	padding-right: ${({ $isLast }) => ($isLast ? '' : '1.75rem')};
	border-bottom: 0.0938rem solid ${colors.GREY_DS.G6};

	.categoryText {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)};
		flex: 1;
		width: max-content;
		color: ${({ $isActive }) => $isActive && colors.BRAND_COLORS.PURPS};
		margin: 2px;
	}

	@media (max-width: 768px) {
		padding: 0 0.875rem;
		${({ $isFirst }) =>
			$isFirst
				? css`
						padding-left: 0;
						margin-left: 1.5rem;
				  `
				: null}
		${({ $isLast }) =>
			$isLast
				? css`
						padding-right: 0;
						margin-right: 1.5rem;
				  `
				: null}
		border-bottom: 0.0625rem solid ${colors.GREY_DS.G6};

		.categoryText {
			${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR)}
		}
	}
`;

export const TabContentContainer = styled.button`
	min-height: 2.0938rem;
	display: flex;
	position: relative;
	height: 100%;

	&:focus-visible {
		outline: 0.125rem solid ${colors.BRAND_COLORS.PURPS} !important;
		outline-offset: -0.125rem;
		border-radius: 0.25rem;
		box-shadow: none;
	}

	&:focus {
		outline: none;
	}
`;

export const TabHighlight = styled.div`
	position: absolute;
	width: 100%;
	border-bottom: 0.0938rem solid ${colors.BRAND_COLORS.PURPS};
	bottom: -0.0938rem;

	@media (max-width: 768px) {
		bottom: -0.0625rem;
		border-bottom: 0.0625rem solid ${colors.BRAND_COLORS.PURPS};
	}
`;
